//const baseUrl = 'http://10.0.2.2:3081'
// const baseUrl = 'http://localhost:3000';
const baseUrl = 'https://backend-gateway.planetknack.com';
var SHA256 = require('crypto-js/sha256');

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  resetPassword: async function ({ jwt, newPassword }) {
    const passwordHash = SHA256(newPassword).toString();
    let resp = await apiCall(
      {
        url: `${baseUrl}/user/reset-password`,
        method: 'POST',
        data: { passwordHash },
      },
      jwt
    );
    return resp;
  },
};

async function apiCall({ method = 'POST', url, data = null }, token) {
  try {
    let resp = await fetch(url, {
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
      ...(data ? { body: JSON.stringify(data) } : {}),
    });
    if (resp.status != 200 && resp.status != 304) {
      let data = await resp.json();
      alert(data.message || 'something went wrong');
      throw data.message || 'something went wrong.';
    }
    return resp.json();
  } catch (error) {
    throw error;
  }
}
