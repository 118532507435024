import { Button, Form, Input } from 'antd';
import 'antd/dist/reset.css';
import api from './api';

function App() {
  const onFinish = async (values) => {
    const { newPassword, confirmPassword } = values;
    if (newPassword !== confirmPassword) {
      return alert('Passwords do not match. Please, enter again');
    }

    const url = new URL(window.location.href);
    const jwt = url.searchParams.get('token');

    await api.resetPassword({ jwt, newPassword });

    alert('password set successfully.');
  };

  return (
    <div
      style={{ justifyContent: 'center', textAlign: 'center' }}
      className="App"
    >
      <p>Reset Your Password</p>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600, justifyContent: 'center', textAlign: 'center' }}
        initialValues={{ remember: true }}
        autoComplete="off"
        align="center"
        onFinish={onFinish}
      >
        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[
            { required: true, message: 'Please input your new password!' },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          rules={[{ required: true, message: 'Please confirm your password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default App;
